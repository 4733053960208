import React, { Component, useState } from "react";
import { renderToString } from "react-dom/server";
import { FAQLinks } from "../../constants/app-links";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAccountInfo } from "./../../store/actions/accounts-actions";
import { getPolicyDocs } from "../../api/policy-docs-api";

import "./faq.css";
import { FrequentlyAskedQuestions } from "./detail/Questions";
import UpdateSuccessView from "../common/update-success-view/UpdateSuccessView";
import {
  contactInformation,
  ContactForm,
} from "../contact/contact.view.component";

import BillingInfo from "../claims/components/billing-info/BillingInfo";
import PetParentInfo from "../view-profile/components/PetParentInfo";
import ReferFriend from "../common/refer-friend/ReferFriend";
import injectExtole from "../common/extole-hoc/extole-hoc";
import Util from "../common/util/util";

class FAQ extends Component {
  constructor(props) {
    super(props);


 
    // Get user's Policy Form
    var thisHolder = this;
    getPolicyDocs()
      .then((res) => {
        for (var i = 0; i < res.policy_documents.length; i++) {
          console.log(res.policy_documents[i].name);
          if (res.policy_documents[i].name.indexOf("Policy Form") >= 0) {
            thisHolder.setState({
              policyFormURL: res.policy_documents[i].resource_url,
            });
            break;
          }
        }
      })
      .catch((error) => {
        thisHolder.fetchingError = error.errorMessage
          ? error.errorMessage
          : "Unable to show the documents. Please try later.";
        thisHolder.setState({ fetching: false });
        window.scrollTo(0, 0);
      });

    // Fetch URL Parameter
    if (props.location.hash) {
      this.sid = props.location.hash.substring(1);
    } else if (props.location.search) {
      var input = props.location.search.trim().replace(/^[?#&]/, "");

      for (const param of input.split("&")) {
        let [key, value] = param.split("=");

        if (key === "qid") this.qid = value;
        else if (key === "sid") this.sid = value;
      }
    }

    // Initialize OpenListMap, load question id to it and extrat string from question answers
    var questionState = new Map();
    for (const Questions of FrequentlyAskedQuestions) {
      Questions.questions.map((item) => {
        questionState.set(item.id, false);
        item.detailHtmlString = this.htmlDecode(
          this.convertToString(item.detail())
        );
        return item;
      });
    }

    if (this.qid) {
      questionState.set(this.qid, true);
    }

    this.state = {
      policyFormURL: undefined,
      questionState: questionState,
      faqSearch: undefined,
      contactFormFieldApi: undefined,
      showContactThankYouPage: false,
    };
  }

  htmlDecode = (encodedString) => {
    var textArea = document.createElement("textarea");
    textArea.innerHTML = encodedString;
    return textArea.value;

    // var map = {amp: '&', lt: '<', gt: '>', quot: '"', '#039': "'"}
    // return encodedString.replace(/&([^;]+);/g, (m, c) => map[c])
  };

    componentDidMount() {

 
      if (this.qid || this.sid) {
         
      this.moveToId(this.qid ? this.qid : this.sid);
    } else {
      window.scrollTo(0, 0);
    }

      this.props.actions.getAccountInfo();
 

        //remove click handlers first to ensure duplicates are not in the DOM
        $('body').first('.preExistingQuestionExampleContainer').off('click');

        $('body').on('click', '.preExistingQuestionExampleContainer', function () {
            
          // do something
          //$(".preExistingAnswerExampleContainer").toggleClass("expanded");
          $(".preExistingAnswerExampleContainer").slideToggle("fast");
          $(".preExistingAnswerExampleContainer").css('display', 'block');
      });
    }
 

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.contactFormFieldApi &&
      nextProps.userAccountDetails &&
      nextProps.userAccountDetails !== prevState.userAccountDetails
    ) {
      let PetParentInfo = nextProps.userAccountDetails.PetParentInfo;

      prevState.contactFormFieldApi.setValue("email", PetParentInfo.Email);
      prevState.contactFormFieldApi.setValue(
        "name",
        PetParentInfo.FirstName + " " + PetParentInfo.LastName
      );
      prevState.contactFormFieldApi.setValue(
        "primaryPhoneNumber",
        PetParentInfo.PrimaryPhone
      );
    }
    return null;
  }

  setShowContactThankYouPage = () => {
    this.setState({
      showContactThankYouPage: true,
    });
  };

  moveToId = (id) => {
    var elem = document.getElementById(id);
    if (elem) {
      elem.scrollIntoView();
    }

    /**
     * Mobile Version has a sticky header, that require additional padding on top.
     * Desktop Version, Text get aligned with the top of the screen.
     * To avoid that, we added minor padding.
     */

    if (window.innerWidth > 960) window.scrollBy(0, -30);
    else window.scrollBy(0, -84);
  };

  questionToggle = (key) => {
    var val = !this.state.questionState.get(key);
    this.setState({
      questionState: this.state.questionState.set(key, val),
    });
  };

  questionOpen = (key) => {
    this.setState({
      questionState: this.state.questionState.set(key, true),
    });

    // scroll to the question
    const element = document.getElementById(key);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  convertToString = (htmlValue) => {
    var string = renderToString(htmlValue);
    return string.replace(/<[^>]+>/g, "");
  };

  faqSearch = (e) => {
    let value = e.target.value.toLowerCase();
    let filteredFAQs;
    this.searchString = e.target.value;

    //We should not show RAF FAQ if the user comes from that state, which is not participating the program.
    if (this.props.enableRAF) {
      filteredFAQs = FrequentlyAskedQuestions;
    } else {
      filteredFAQs = FrequentlyAskedQuestions.filter(
        (q) => q.id !== "ReferAFriend"
      );
    }

    var searchResult = [];

    if (value && value.length >= 1) {
      for (const Questions of filteredFAQs) {
        searchResult.push.apply(
          searchResult,
          Questions.questions.reduce(function (accumulator, currentValue) {
            if (currentValue.title.toLowerCase().indexOf(value) > -1) {
              currentValue.commentString = undefined;
              accumulator.push(currentValue);
            } else if (
              currentValue.detailHtmlString.toLowerCase().indexOf(value) > -1
            ) {
              var index = currentValue.detailHtmlString
                .toLowerCase()
                .indexOf(value);
              var commentStringIndex = "";

              if (index - 20 >= 0) {
                commentStringIndex = currentValue.detailHtmlString.substring(
                  index - 20
                );
                commentStringIndex =
                  ".." +
                  commentStringIndex.substring(
                    commentStringIndex.indexOf(" ") + 1
                  );
              } else commentStringIndex = currentValue.detailHtmlString;

              currentValue.commentString = commentStringIndex;
              accumulator.push(currentValue);
            }

            return accumulator;
          }, [])
        );
      }

      this.setState({
        faqSearch: searchResult,
      });
    } else {
      this.setState({
        faqSearch: undefined,
      });
    }
  };

  blurSearch = () => {
    this.setState({
      faqSearch: undefined,
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showContactThankYouPage ? (
          <UpdateSuccessView
            statusHeader="Your message has been successfully submitted."
            title="Thanks for getting in touch!"
          >
            <div>
              We’ve received your message and will respond to you as soon as we
              can.
            </div>
            <button
              className="button-secondary home-button-thank-you"
              type="submit"
              onClick={() => {
                this.props.history.push("/");
              }}
            >
              Home
            </button>

            {contactInformation()}
          </UpdateSuccessView>
        ) : (
          <div className="container faq-container " onClick={this.blurSearch}>
            <h1 className="d-flex align-items-center title">
              Frequently Asked Questions
            </h1>

            <div className="row">
              <div className="col-12 col-lg-8 container-view">
                <p className="d-block">
                  We're here to make it easier
                  for you to care for your pet and easier on both of you if your
                  pet has an unexpected illness or injury. We're also here to
                  make what we do and what we offer easy to understand. Whenever
                  you have a{" "}
                  <a
                    href={FAQLinks.MainSiteURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    pet insurance
                  </a>{" "}
                  question or need to review your options, please{" "}
                  <a
                    href={FAQLinks.MainSiteContactURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    email us
                  </a>{" "}
                  or call us at 1-855-898-8991.
                </p>
                <h4 className="d-flex d-md-none align-items-center faq-search-title">
                  FAQ Search
                </h4>
                <h4 className="d-none d-md-block search-text">
                  Please search for a question.
                </h4>

                <div className="d-md-none suggestion-mobile-container">
                  {this.faqSearchBox()}
                </div>
                <div className="d-none d-md-block"> {this.faqSearchBox()} </div>

                {this.faqSearchAnswer()}

                {FrequentlyAskedQuestions.map((item, index) =>
                  this.questionelement(item, index, this.props.userAccountDetails.PhysicalAddress)
                )}
    
                <div>
                <h4 className="faq-title">Still have a Question?</h4>
                  <a href="https://www.healthypawspetinsurance.com/contact" target="_blank" rel="noopener noreferrer" className="faqQuestion">Contact Us</a><br/>
                  <a href="https://www.healthypawspetinsurance.com/healthy-paws-media-press-releases-announcements" target="_blank" rel="noopener noreferrer" className="faqQuestion">Media Inquiries</a>
                </div>

                <h4 className="d-flex align-items-center faq-title margin-bottom">
                  How can we help ?
                </h4>
                <ContactForm
                  setContactFormFieldAPI={this.setContactFormFieldAPI}
                  showDropDown={false}
                  responseArray={[]}
                  setShowContactThankYouPage={this.setShowContactThankYouPage}
                  fetching={this.props.userAccountDetailsFetching}
                />
                <div className="d-lg-none d-block">{contactInformation()}</div>
              </div>

              {this.rightColumn(this.props.userAccountDetails)}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  rightColumn = (userAccountDetails) => {
    return (
      <div className="rightColumn d-none d-lg-block col-lg-4 ">
        {/* <div className="col-refer-friend">
          <ReferFriend />
        </div> */}
        {userAccountDetails && (
          <React.Fragment>
            <BillingInfo
              creditCard={userAccountDetails.Last4CreditCard}
              billingInfo={userAccountDetails.BillingAddress}
              status={userAccountDetails.Status}
              billingDay={userAccountDetails.BillingDay}
              premium={userAccountDetails.MonthlyPremium}
              newMonthlyPremium={userAccountDetails.NewMonthlyPremium}
              changeEffectiveDate={userAccountDetails.ChangeEffectiveDate}
            />

            <div className="billing-info billing-info-margin">
              <div className="info-header text-primary">
                Contact Information
              </div>
              <div className="info-body info-body-padding pt-0 d-flex flex-column justify-content-between">
                <PetParentInfo
                  parentInfo={userAccountDetails.PetParentInfo}
                  address={userAccountDetails.PhysicalAddress}
                  mailingAddress={userAccountDetails.MailingAddress}
                  hidePassword={true}
                  policyEffectiveDate={userAccountDetails.PolicyEffectiveDate}
                  status= {userAccountDetails.Status}
                />
              </div>
              <div className="info-footer" />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  setContactFormFieldAPI = (api) => {
    this.setState({
      contactFormFieldApi: api,
    });
  };

  questionelement = (item, index, PhysicalAddress) => {
    //We should not show RAF FAQ if the user comes from that state, which is not participating the program.
    if (item.id === "referafriend") {
      if (!this.props.enableRAF) {
        return null;
      }
    }
    return (
      <div key={index} className="d-flex flex-column">
        <h4 id={item.id} className="d-flex align-items-center faq-title">
          {item.title}
        </h4>
        {
          item.title === "Concerns Regarding Your Pet's Prior Medical Treatment" ? 
          <div className="faqAnswer" >
             <div>
             <span style={{fontWeight:'400'}}>Here are a few additional examples of potential {" "}
          <a onClick={() => this.handleClick('pre-existing_condition')} ref={element => { 
         if (element) element.style.setProperty('color', 'black', 'important');}} style={{fontWeight:"bold",fontStyle:"italic",textDecoration:"none",cursor:"pointer"}} rel="noopener noreferrer">
          pre-existing conditions
          </a> which may be excluded
                   from coverage. Please note that</span> coverage determinations can only be made based on all
                   relevant facts and circumstances of a particular claim, the terms and conditions of the policy
                   and any applicable laws. Your pet’s medical history requires review to determine coverage
                   eligibility.
                </div>
          </div> 
          : null
        }
        {item.questions.map((questionItem, index) => (
          <React.Fragment key={index}>
            <h4
              id={questionItem.id}
              className="d-flex align-items-center faqQuestion"
              onClick={(e) => {
                this.questionToggle(questionItem.id);
              }}
            >
              {questionItem.title}
            </h4>
            {this.state.questionState.get(questionItem.id) &&
              questionItem.detail(this.state.policyFormURL, PhysicalAddress.State, this.questionOpen, this.props.userAccountDetails.Property)}
          </React.Fragment>
        ))}
      </div>
    );
  };

  faqSearchAnswer = () => {
    return (
      <React.Fragment>
        {this.state.faqSearch && this.state.faqSearch.length > 0 && (
          <React.Fragment>
            <div
              className="d-flex col-lg-12 flex-column suggestion-answer-container container"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <h4 className="font-600">
                Search Results for "
                <span className="font-bold"> {this.searchString} </span>"
              </h4>
              {this.state.faqSearch.map((item, index) => {
                return (
                  <div key={index} className="search-result-element">
                    <h4
                      className="faq-search-question"
                      onClick={() => this.handleClick(item.id)}
                    >
                      {this.highlightString(item.title)}
                    </h4>
                    <div className="d-flex">
                      <div className="suggestion-answer">
                        {this.highlightString(
                          item.commentString
                            ? item.commentString
                            : item.detailHtmlString
                        )}
                      </div>
                      <a onClick={() => this.handleClick(item.id)}> View </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  faqSearchBox = () => {
    return (
      <React.Fragment>
        <div id="search-input-wrapper">
          <input
            id="faq-search-input"
            className="suggestion-input"
            onChange={(e) => this.faqSearch(e)}
            onFocus={(e) => this.faqSearch(e)}
            placeholder={"Search for a Question"}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </div>
        <div className="d-md-none suggestion-icon-wrapper">
          <i className="fa fa-search suggestion-icon-mobile" />
        </div>
        <div className="d-none d-md-block suggestion-icon-wrapper">
          <i className="fa fa-search suggestion-icon" />
        </div>
      </React.Fragment>
    );
  };

  handleClick = (id) => {
    this.setState(
      {
        questionState: this.state.questionState.set(id, true),
        faqSearch: undefined,
      },
      () => {
        this.moveToId(id);
      }
    );

    if (document.getElementById("faq-search-input"))
      document.getElementById("faq-search-input").value = "";
  };

  highlightString = (setence) => {
    if (!setence) return "";

    var idx = setence.toLowerCase().indexOf(this.searchString.toLowerCase());
    if (idx > -1)
      return [
        <span>
          {setence.substring(0, idx)}
          <span className="font-bold" key={idx}>
            {setence.substring(idx, idx + this.searchString.length)}
          </span>
          {setence.substring(idx + this.searchString.length)}
        </span>,
      ];
    else return setence;
  };
}

const mapStateToProps = (state) => ({
  userAccountDetails: state.account.userAccountDetails,
  userAccountDetailsFetching: state.account.fetching,
  enableRAF: state.account.enableRAF,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAccountInfo,
    },
    dispatch
  ),
});

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(FAQ);

export default injectExtole(connect(mapStateToProps, mapDispatchToProps)(FAQ), {
  ctaID: "extole-faq-refer",
});
