import React from 'react'
import { Link } from "react-router-dom";
import {AppLinks} from "../../constants/app-links"
import './ViewPetDetail-Components.css'
import Util from  "../common/util/util"

    export const Header = (props) => {
        return (
        <div className={props.className + " pet_detail_header d-flex justify-content-between align-items-center"}>
          <h1>View Pet Details</h1>
          <AddAPetButton className="d-none d-md-block add_pet_button">Add a Pet+</AddAPetButton>
        </div>
        )
    }

    export const AddAPetButton = (props) => {
      return(

        <Link to={AppLinks.policy.addPet}>
        <button className={"button-secondary " + props.className}>Add a Pet+</button>
        </Link>
      );
    }

    export const PetDetailListItem = (props) => {
      return (
        <div className={"row d-fle flex-row justify-content-center align-items-center " + props.className} style={{ height: '28px'}}>
          <h4 className="col-7 detail-title">{props.title}</h4>
          <span className="col-5 detail-value" >{props.value}</span>
        </div>
      )
    }
    export const PetDetailListItemCancelStatus = (props) => {
      return (
        <div className={"row d-fle flex-row justify-content-center align-items-center " + props.className} style={{ height: '28px'}}>
          <h4 className="col-7 detail-title">{props.title}</h4>
          <span className="col-5 detail-value text-danger">{props.value}</span>
        </div>
      )
    }

    export const WaitingPeriod = (props) => {
      return(
        !props.userAccountDetails.ModelLawInEffect ? 
      <div className="waiting_period">
        <h4 className="waiting_period_text"><span className="truncate">{props.name}</span>'s Waiting Period</h4>
        <div className="waiting_terms">• You can submit claims for { props.userAccountDetails.ModelLawInEffect == true ? "accidents" : "accidents and illnesses" } that occur after {props.protected_since}</div>
        { !props.stateVerification && props.age < 6 && <div className="waiting_terms">• There is a { props.userAccountDetails.ModelLawInEffect == true ? "30-day" : "12-month" } waiting period for hip dysplasia for pets younger than six years.</div>}  
      </div>
      :
      <div className="waiting_period">
        
        <h4 className="waiting_period_text"><span className="truncate">{props.name}</span>'s Waiting Period</h4>
        <ul className='waiting_terms'>
        <li>Your coverage begins on {Util.getFormattedDate(props.userAccountDetails.PolicyEffectiveDate)} 12:01 AM plus applicable waiting periods.</li>
        <li>A 15-day waiting period applies for illnesses, injuries or orthopedic conditions (other than hip dysplasia) not resulting from an accident.</li>
          {props.age < 6 && <li>A 30-day waiting period applies to hip dysplasia not resulting from an accident.</li>}
        <li>However, waiting periods will not apply if waived by us upon completion of a complete clinical examination</li>
        </ul>
      </div>
      );
    }